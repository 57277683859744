import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import TopArea from "./TopArea";

function PrivacyPolicy() {
  const termsMarkdown = `
# Privacy Policy

_Last updated: December 24, 2024_

Welcome to Opnrs (“the App”). This Privacy Policy explains how we collect, use, and protect the information you provide when you use the App. By using Opnrs, you agree to the practices outlined in this policy.

## 1. Overview
- **Name**: Opnrs  
- **Purpose**: A mobile platform designed to be a dynamic conversation starter tool.  
- **Support Email**: [support@opnrs.com](mailto:support@opnrs.com)  

Opnrs focuses on providing prompts and conversation topics, with minimal collection of data. The App is intended to operate primarily offline, and most user data remains on your device.

## 2. Data Collection and Use
- **Usage Data via Amplitude**: We use a third-party analytics service, Amplitude, to collect non-personal usage data (e.g., clicks, user actions) for the sole purpose of understanding App performance and enhancing the user experience.  
- **No Personal Information**: We do not collect any personally identifiable information such as names, email addresses, or contact details.  
- **Offline Operation**: Much of the App’s functionality works offline, with data stored locally on your device.

### How We Use Usage Data
- **Performance Analysis**: To track which features are most used and how the App performs under varying conditions.  
- **User Experience Improvements**: To identify trends and potential issues, enabling us to enhance the App’s features and stability.

## 3. Data Storage and Retention
- **Amplitude’s Storage**: Any usage data collected is securely stored by Amplitude and is only accessed by authorized personnel to analyze App performance.  
- **Local Device Storage**: Data and user settings are primarily kept on your device for as long as you have the App installed or until you manually delete them.  
- **Retention Period**: We retain usage data only as long as necessary to fulfill the purposes described in this Policy, after which it is either deleted or de-identified.

## 4. User Rights
- **Opt-Out of Analytics**: Where supported, you may be able to disable analytics tracking via your device’s privacy settings or through the App’s settings menu. Please note that opting out may limit certain functionality or insights we gain for future improvements.  
- **Contact Us**: If you have questions or concerns about how your data is handled, please reach out to us at [support@opnrs.com](mailto:support@opnrs.com).

## 5. Third-Party Services
- **Analytics (Amplitude)**: We rely on Amplitude for analytics. Amplitude’s own privacy policy applies to how they handle the data collected on our behalf.  
- **No Additional Third Parties**: We do not share or sell any collected data to advertisers or other external services.

## 6. Security Measures
- **Reasonable Safeguards**: We use commercially reasonable measures to safeguard the data collected and stored by Amplitude.  
- **Device-Level Security**: Data stored on your device is protected by your device’s security settings (e.g., passwords, biometrics), which we strongly encourage you to use.

## 7. Cookies and Tracking Technologies
- **No Cookies**: Opnrs does not use cookies or similar tracking technologies.  
- **Analytics Only**: Our only form of tracking is via Amplitude for basic usage analytics.

## 8. Legal Compliance
Opnrs follows general best practices for user privacy, but is not specifically tailored to comply with regulations such as GDPR or CCPA. As an App designed for providing conversation starters, we collect minimal data to maintain user privacy.

## 9. Changes to This Privacy Policy
We may update or revise this Privacy Policy as the App evolves. Material changes will be communicated via in-App notifications or updates. Your continued use of the App after any modifications signifies acceptance of the revised policy.

## 10. Contact Information
For any questions or concerns regarding your privacy, data use, or this policy, please contact:
- **Email**: [support@opnrs.com](mailto:support@opnrs.com)

---
`;

  return (
    <div className="markdown-page">
      <TopArea />
      <div className="markdown-content">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            // Using generic markdown classes instead of terms-specific ones
            h1: ({ node, ...props }) => <h1 {...props} />,
            h2: ({ node, ...props }) => <h2 {...props} />,
            h3: ({ node, ...props }) => <h3 {...props} />,
            p: ({ node, ...props }) => <p {...props} />,
            a: ({ node, ...props }) => <a {...props} />,
            ul: ({ node, ...props }) => <ul {...props} />,
            ol: ({ node, ...props }) => <ol {...props} />,
            li: ({ node, ...props }) => <li {...props} />,
            blockquote: ({ node, ...props }) => <blockquote {...props} />,
            code: ({ node, ...props }) => <code {...props} />,
          }}
        >
          {termsMarkdown}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
