import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import Entrance from "./Entrance";
import TermsAndConditions from "./TermsAndConditions";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/" element={<Entrance />} />
        <Route path="*" element={<Entrance />} />
      </Routes>
    </Router>
  );
}

export default App;
