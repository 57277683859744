import React from "react";
import { ReactComponent as OpenersLogo } from "./Entrance/images/openersLogoWeb.svg";
import { Link } from "react-router-dom";

const TopArea = () => {
  return (
    <div className="topArea">
      <div className="topArea__content">
        <div className="container">
          <div className="logo-container">
            <Link to="/">
              <OpenersLogo className="logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopArea;
