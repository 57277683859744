import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import TopArea from "./TopArea";

function TermsAndConditions() {
  const termsMarkdown = `
# Terms and Conditions

_Last updated: December 24, 2024_

## 1. Acceptance of Terms
By downloading, installing, or using opnrs (“the App”), you agree to these Terms and Conditions. If you do not agree to all of these Terms, you must discontinue using the App immediately.

## 2. App Overview
- **Name**: opnrs  
- **Purpose**: A mobile platform offering categorized, depth-based conversation starters (fun, casual, and deep) for various settings such as professional networking, personal discussions, and casual interactions.  
- **Support Email**: [support@opnrs.com](mailto:support@opnrs.com)

## 3. Data and Privacy
- **Usage Data**: We collect usage data through third-party analytics services. This may include click data, session durations, and general activity metrics.  
- **No Personal Information**: We do not collect or store personal information (e.g., names, email addresses).  
- **Data Usage**: Any data collected is used solely to improve the user experience and app functionality. We do not sell or share this data for marketing or commercial purposes.

## 4. Content and Ownership
- **AI-Generated Content**: Some of the conversation starters and topics in the App may be created by automated tools, including artificial intelligence. The developer does not claim exclusive ownership over this automatically generated material.  
- **Limited Rights**: We provide access to these prompts and questions without asserting unique or exclusive rights.  
- **Prohibited Copying**: You must not copy or reproduce the App’s prompts or questions for commercial use without explicit permission. This is to protect the App’s unique offerings to the extent permitted by law.

## 5. Intellectual Property (Design and Branding)
- **Design Ownership**: The App’s layout, user interface, visuals, branding, and overall design (“Design”) are the property of the developer.  
- **Unauthorized Replication**: You agree not to replicate, copy, or distribute the App’s Design in a way that could mislead others into thinking you have the rights or endorsements to do so.  
- **Protection**: We reserve all legal rights to prevent unauthorized use, including seeking legal remedies for infringement.

## 6. Usage Restrictions
You agree **not** to:
1. Use the App for illegal activities, including but not limited to promoting hate speech or defamation.  
2. Copy, reproduce, or distribute any portion of the App’s questions or prompts for commercial purposes without written consent.  
3. Attempt to reverse-engineer, decompile, or exploit any part of the App’s functionality or security.  
4. Repurpose or misappropriate the App’s Design or branding in any way that violates these Terms.

## 7. Limitation of Liability
- **As-Is Basis**: The App is provided “as is” without warranties of any kind, either express or implied.  
- **No Warranty**: We do not warrant that the App will be uninterrupted, error-free, or completely secure.  
- **Limited Liability**: In no event shall the developer (as a sole creator) be held liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the App.  
- **Third-Party Services**: We are not responsible for issues caused by third-party integrations or technical errors outside our control.

## 8. Dispute Resolution and Arbitration
- **Arbitration**: Any dispute arising out of or relating to these Terms will be resolved through binding arbitration, rather than in court, except as otherwise provided by law.  
- **Jurisdiction**: Such arbitration shall take place in Phoenix, Arizona, or any location determined by the developer.  
- **No Class Actions**: You agree to resolve disputes on an individual basis and waive any right to participate in a class-action lawsuit.

## 9. Payment Terms
- **No Current Payments**: There are no current subscriptions or in-app purchases.  
- **Future Changes**: If payment models are introduced in the future, separate terms and advance notifications will be provided.

## 10. Modifications
We reserve the right to modify or replace these Terms and Conditions at any time. Notice will be provided through the App or via other means. Continued use of the App after any changes signifies acceptance of the new Terms.

## 11. Termination
We do not currently offer a formal account registration process, but we reserve the right to disable or limit your access if you seriously violate these Terms, especially concerning unauthorized copying or misuse of the App’s Design or content.

---

If you have any questions about these Terms and Conditions, please contact us at [support@opnrs.com](mailto:support@opnrs.com).
`;

  return (
    <div className="markdown-page">
      <div className="markdown-content">
        <TopArea />
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            // Using generic markdown classes instead of terms-specific ones
            h1: ({ node, ...props }) => <h1 {...props} />,
            h2: ({ node, ...props }) => <h2 {...props} />,
            h3: ({ node, ...props }) => <h3 {...props} />,
            p: ({ node, ...props }) => <p {...props} />,
            a: ({ node, ...props }) => <a {...props} />,
            ul: ({ node, ...props }) => <ul {...props} />,
            ol: ({ node, ...props }) => <ol {...props} />,
            li: ({ node, ...props }) => <li {...props} />,
            blockquote: ({ node, ...props }) => <blockquote {...props} />,
            code: ({ node, ...props }) => <code {...props} />,
          }}
        >
          {termsMarkdown}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default TermsAndConditions;
